import { Fragment } from "react";
import Avatar from "@components/avatar";
import { Check } from "react-feather";
const Success = (props) => {
    return (
    <Fragment>
      <div className="toastify-header">
        <div className="title-wrapper">
          <Avatar size="sm" color="success" icon={<Check size={12} />} />
          <h6 className="toast-title" color="success">
            Message
          </h6>
        </div>
        <small className="text-muted">a second ago</small>
      </div>
      <div className="toastify-body">
        <span role="img" aria-label="toast-text">
          👋 {props.message}
        </span>
      </div>
    </Fragment>
    )
}
export default Success;
