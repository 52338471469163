/**
 * Author: Bharat Prajapat
 * Project: Integrisheild
 * Frontend Tech Stack : React 17, AWS Amplify, AWS Congnito, 
 */
// ** React Imports
import { Suspense, lazy } from 'react'
import ReactDOM from 'react-dom'

// ** Redux Imports
import { Provider } from 'react-redux'
import { store } from './redux/store'

// ** Intl & ThemeColors Context
import { ToastContainer } from 'react-toastify'
import { ThemeContext } from './utility/context/ThemeColors'

// ** Intl, CASL & ThemeColors Context
import ability from './configs/acl/ability'
import { AbilityContext } from './utility/context/Can'

// ** Spinner (Splash Screen)
import Spinner from './@core/components/spinner/Fallback-spinner'

// ** Ripple Button
import './@core/components/ripple-button'

// ** PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import 'prismjs/components/prism-jsx.min'

// ** React Perfect Scrollbar
import 'react-perfect-scrollbar/dist/css/styles.css'

// ** React Toastify
import '@styles/react/libs/toastify/toastify.scss'

// ** Core styles
import './@core/assets/fonts/feather/iconfont.css'
import './@core/scss/core.scss'
import './assets/scss/style.scss'

// ** Service Worker
import * as serviceWorker from './serviceWorker'

import Amplify from 'aws-amplify'
const getAuthToken = async () =>  {
  const token =  await new Buffer.from(`${process.env.REACT_APP_OPENSEARCH_USERNAME}:${process.env.REACT_APP_OPENSEARCH_PASSWORD}`).toString("base64");
  if (token) {
    return `Basic ${token}`;
  }
}
Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID,
    mandatorySignIn: process.env.REACT_APP_AWS_MANDATORY_SIGN_IN
  },
  Storage: {
    bucket: process.env.REACT_APP_AWS_DOC_BUCKET,
    region: process.env.REACT_APP_AWS_REGION,
    userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID
  },
  API: {
    endpoints: [
      {
        name: process.env.REACT_APP_AWS_API_NAME,
        endpoint: process.env.REACT_APP_AWS_API_GATEWAY_ENDPOINT
      },
      {
        name: 'S3',
        endpoint: ''
      },
      {
        name: process.env.REACT_APP_AWS_OPENSEARCH_API_NAME,
        endpoint: process.env.REACT_APP_OPENSEARCH_URL,
        custom_header: async () => {
          return { "Content-Type": "application/json", Authorization: await getAuthToken()  };
          // Alternatively, with Cognito User Pools use this:
          // return { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` }
          // return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
        }
      }
    ]
  },
  ssr: true 
})  

const appSyncConfig = {
  aws_appsync_graphqlEndpoint: process.env.REACT_APP_AWS_APPSYNC_GRAPHQL_ENDPOINT,
  aws_appsync_region: process.env.REACT_APP_AWS_REGION,
  aws_appsync_authenticationType: process.env.REACT_APP_AWS_APPSYNC_AUTHENTICATION_TYPE
}

Amplify.configure(appSyncConfig);

// ** Lazy load app
const LazyApp = lazy(() => import('./App'))


ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<Spinner />}>
    <AbilityContext.Provider value={ability}>
      <ThemeContext>
        <LazyApp />
        <ToastContainer newestOnTop />
      </ThemeContext>
      </AbilityContext.Provider>
    </Suspense>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
