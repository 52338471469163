import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import { AbilityContext } from "@src/utility/context/Can";
import Successfully from "../share/SuccessMsg";
import { toast } from "react-toastify";
import { API, Auth } from "aws-amplify";
import { Redirect } from 'react-router';

const initialUser = () => {
  const item = window.localStorage.getItem("userData");
  return item ? JSON.parse(item) : {};
};
const signOut = async () => {
  try {
    await Auth.signOut({ global: true });
    document.cookie.split(";").forEach(function (c) {
      document.cookie = c.replace(/^ +/, "").replace(/=.*/, `=;expires=${new Date().toUTCString()};path=/`);
    });
    localStorage.clear();
    // window.location.reload();
    await toast.error(<Successfully message="Your Parent Client is Deleted" />, {
      icon: false,
      hideProgressBar: true
    });
    setTimeout(window.location.reload(), 2000)
    return setTimeout(Redirect('/login'), 3000)
  } catch (error) {
    console.log('error signing out: ', error);
  }
}
export const fetchUseData = createAsyncThunk('fetchUserData', async (userData) => {
  const ClienkSK = userData?.attributes["custom:industryId"];
  const ClientRole = userData?.attributes["custom:industryName"];
  const chunks = ClienkSK.split('#');
  const result = [];
  for (let i = 0; i < chunks.length; i += 2) {
    result.push(chunks.slice(i, i + 2).join('#'));
  }
  if (ClientRole === 'Level Two' || ClientRole === 'Level One') {
    const searchOption = {
      query: {
        bool: {
          must: [
            {
              wildcard: {
                'SK.keyword': {
                  value: result[0],
                  case_insensitive: true
                }
              }
            },
            {
              wildcard: {
                "itemRole.keyword": `tenant*`
              }
            },
            {
              term: {
                isDeleted: {
                  value: true
                }
              }
            }
          ]
        }
      }
    };
    return await API.post('opensearch', '/client/_search/', { body: searchOption }); //api name, path, post data 
  }
  if (ClientRole === 'Level Two') {
    const LevelOneParams = {
      query: {
        bool: {
          must: [
            {
              wildcard: {
                'SK.keyword': {
                  value: result[1],
                  case_insensitive: true
                }
              }
            },
            {
              wildcard: {
                "itemRole.keyword": `tenant*`
              }
            },
            {
              term: {
                isDeleted: {
                  value: true
                }
              }
            }
          ]
        }
      }
    }
    return await API.post('opensearch', '/clienttwo/_search/', { body: LevelOneParams });
  }
})
export const authSlice = createSlice({
  name: "authentication",
  initialState: {
    userData: initialUser()
  },
  reducers: {
    handleLogin: (state, action) => {
      state.userData = action.payload;
      const clientLevelData = {
        Id: {
          clientIdData: action.payload.attributes['custom:industryId'],
          clientFilterID: action.payload.attributes['custom:clientFilterId']
        }
      }
      localStorage.setItem("userData", JSON.stringify(action.payload));
      localStorage.setItem("clientLevelData", JSON.stringify(clientLevelData));
      const userData = JSON.parse(localStorage.getItem("userData"));
      const role = userData.attributes["custom:role"];
      if (role === 'admin') {
        toast.error(<Successfully message="Please Select Client" />, {
          icon: false,
          hideProgressBar: true
        });
      }
    },
    handleLogout: (state) => {
      state.userData = {};
      localStorage.removeItem("userData");
      localStorage.removeItem("clientLevelData");
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchUseData.fulfilled, (state, action) => {
        // console.log('checking auto signout if parent client is deleted', state, action);
        if (!action?.payload?.hits?.hits?.length <= 0) {
          signOut()
        }
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchUseData.pending, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchUseData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
});
export const { handleLogin, handleLogout } = authSlice.actions;
export default authSlice.reducer;
